<template>
        <Content
        :route="'/modulos'"
    title="Cadastrar módulo"
    :action="action" :hasBack="action" @event="moduleSubmit(selectedSystemToCopy, selectedModuleToCopy)"
  >
  <template #selects>
    <b-row style="flex-direction: row; margin-top: 1%;">
      <b-col cols="4">
    <b-form-group id="select-title">
                <b-form-select
                    id="sort-by-select"
                    :options="options"
                    v-model="selectedSystem"
                    @change="getItems"
                    class="select"
                >
                   <template #first>
                    <b-form-select-option :value="'selecionar'"
                        >Selecionar sistema</b-form-select-option
                    >
                   </template>
                </b-form-select>
                </b-form-group>
      </b-col></b-row>
  </template>
  <b-form ref="form">
    <!--
    <b-row class="card" id="clonar_dicionarios">
      <b-col cols="12">
      <b-row>
      <b-col style="place-self: center;" cols="3" >
            <h3 class="title">
              Copiar de outro módulo
            </h3>
            <span></span>
      </b-col>
      <b-col :cols="2">
            <label class="title">
          </label>
            <span></span>
          <div
            class="d-flex flex-row justify-content-around align-items-center"
          >
            <b-form-select :options="allModules" v-model="selectedSystemToCopy">
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Selecione um sistema para copiar
                  </b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
      </b-col>
      <b-col :cols="$route.name === 'CadastrarModulos'?'2':'3'">
            <label class="title">
            </label>
            <span></span>
          <div
            class="d-flex flex-row justify-content-around align-items-center"
          >
            <b-form-select :options="allRoutes" v-model="selectedModuleToCopy">
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Selecione uma rota para copiar
                  </b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
      </b-col>
      <b-col style="place-self: center;" cols="3">
        <LoadingButton :button-class="'btn-turquesa'" :isLoading="loadingCopy" @event="copyModule(selectedSystemToCopy, selectedModuleToCopy)">
        Copiar
      </LoadingButton>
      </b-col>
  </b-row>
      </b-col>
     </b-row>
     -->
  <b-row class="card" id="clonar_dicionarios2">

     <!-- row do form -->
       <b-row>
        <b-col v-for="field in fieldsExtra" :key="field.key" :cols="field.col">
          <b-form-group :id="'input-add-group' + field.key">
         <!--    <div class="h-separator"> -->
              <label :for="'input-add-' + field.key" class="title"
                >{{ field.label }}{{ field.required ? " *" : "" }}</label
              >
              <span></span>
           <!--  </div> -->
           <b-form-input
              v-if="['text', 'email', 'tel', 'number'].includes(field.type)"
              :id="'input-add-' + field.key"
              v-model="form[field.key]"
              :type="field.type"
              v-maska="field.mask"
              :placeholder="field.label"
              :required="field.required"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      </b-row>

     <b-row class="card" id="rotas">
        <h3 class="title text-justify">Rotas</h3>
        <b-row>
          <b-col cols="6">
          <b-form-group id="select-title" style="top:0">
                <b-form-select
                    id="sort-by-select"
                    :options="allModulesRoute"
                    v-model="selected"
                    @change="getItems"
                    class="select"
                >
                   <template #first>
                    <b-form-select-option :value="'selecionar'"
                        >Selecionar rota</b-form-select-option
                    >
                   </template>
                </b-form-select>
                </b-form-group>
        </b-col>
        <b-col cols="2">
                <div @click="selectAll()" class="title-btn" style="background-color: #00DDEE; margin: auto;">
                <span class="label">Todas</span>
            </div>
          </b-col>
        </b-row>
      <b-row>
        <b-form-tags
        size="lg"
        class="mb-2 modulo_tag text-dark bg-white"
        tag-class="modulo_tag"
        tag-variant="outline-light"
        separator=" "
        :placeholder="!value.length ? 'Escreva para adicionar ou selecione': ''"
        add-on-change
        no-outer-focus
        v-model="value"></b-form-tags>
      </b-row>
     </b-row>
    </b-form>
    </Content>
</template>
<script>
import Content from '../components/content/index'
import { api, request } from '@/services'
import { toast, errorFormater } from '@/utils'
// import LoadingButton from '../components/Shared/LoadingButton.vue'
export default {
  components: { Content },
  data () {
    return {
      value: [],
      form: {},
      selectedSystem: null,
      selectedSystemToCopy: null,
      selectedModuleToCopy: null,
      selected: null,
      loadingCopy: false
    }
  },
  created () {
    toast(this, 'success', 'Rotas', 'Selecione um sistema para começar')
  },
  beforeCreate () {
    this.$store.dispatch('modulos/init', { self: this })
    this.$store.dispatch('rotas/init', { self: this })
    this.$store.dispatch('sistemas/init', { self: this })
  },
  mounted () {
    const reloaded = localStorage.getItem('reloaded')
    if (reloaded !== 'true') {
      localStorage.setItem('reloaded', 'true')
      location.reload()
    }

    if (this.$route.query.group) {
      this.selectedSystem = this.$route.query.group
      const editedModule = this.$store.getters.getEditedModule
      const tagsFromExtraFields = editedModule.objects.map(field => field.key)
      this.value = tagsFromExtraFields
      // Assuming fieldsExtra is an array of field objects
      console.log(editedModule)
      for (const field of this.fieldsExtra) {
        this.form[field.key] = editedModule[field.key]
      }
      this.form.key = this.form.key.split('_').slice(1).join('_')
    }
  },
  computed: {
    fieldsExtra () {
      return this.$store.state.modulos.formFields
    },
    action () {
      return { options: { label: 'Salvar', icon: 'save', hasBack: 'arrow' } }
    },
    options () {
      if (this.$store.state.sistemas.items && this.$store.state.sistemas.items.groups) {
        const item = Object.entries(this.$store.state.sistemas.items.groups).map(a => a[1])
        const value = item.map(a => ({ value: a.id, text: a.key }))
        return value.map(v => v.text)
      }
      return []
    },
    allRoutes () {
      if (this.$store.state.rotas.items.objects) {
        const item = this.$store.state.rotas.items.objects.map((a) => a)
        const value = item.map((a) => ({ value: a.id, text: a.key }))
        return value.map((v) => v.text)
      }
      return []
    },
    allSystems () {
      if (this.$store.state.sistemas.items && this.$store.state.sistemas.items.groups) {
        const item = Object.entries(this.$store.state.sistemas.items.groups).map((a) => a[1])
        const value = item.map((a) => ({ value: a.id, text: a.key }))
        return value.map((v) => v.text)
      }
      return []
    },
    allModules () {
      if (this.$store.state.modulos.items && this.$store.state.modulos.items.groups) {
        const item = Object.entries(this.$store.state.modulos.items.groups).map((a) => a[1])
        const value = item.filter((a) => a.type === 'Module').map((a) => ({ value: a.id, text: a.key }))
        return value.map((v) => v.text)
      }
      return []
    },
    allModulesRoute () {
      if (this.$store.state.rotas.items.objects) {
        const item = this.$store.state.rotas.items.objects.filter((a) => a.groups[0]?.key === this.selectedSystem)
        const value = item.map((a) => ({ value: a.id, text: a.key }))
        return value.map((v) => v.text)
      }
      return []
    }
  },
  beforeDestroy () {
    localStorage.removeItem('reloaded')
  },
  methods: {
    selectAll () {
      this.selected = this.allModulesRoute
      this.value = this.selected
    },
    getItems () {
      this.value.push(this.selected)
    },
    async moduleSubmit (system, destino) {
      await this.submit()

      if (this.$store.getters.moduleToCopy !== null) {
        await this.copyModule(system, destino)
        this.value.push(destino)
        for (const route of this.value) {
          await this.updateRoute(route)
        }
      }
    },
    submit () {
      const erp = Object.entries(this.$store.state.sistemas.items.groups).map((a) => a[1]).filter((a) => a.key === this.selectedSystem).map(i => i.key)[0]
      this.form.key = erp + '_' + this.form.key
      console.log(this.form)
      const f = Object.assign({
        ...this.form,
        public: 1,
        master_group_id: Object.entries(this.$store.state.sistemas.items.groups).map((a) => a[1]).filter((a) => a.key === this.selectedSystem).map(i => i.id)[0]
      })
      console.log(f)
      if (this.$route.query.group) {
        console.log({ ...f, type: 'Module', group_id: this.$store.getters.getEditedModule.id, objects: this.value })
        request.put('group/update', { ...f, type: 'Module', group_id: this.$store.getters.getEditedModule.id, objects: this.value },
          (response) => {
            toast(this, 'success', 'Atualizado com sucesso')
            console.log(response)
            this.$router.push({ path: '/modulos' })
          },
          (error) => {
            toast(this, 'danger', 'Erro', errorFormater(error))
            return Promise.reject(error)
          })
      } else {
        console.log({ ...f, objects: this.value })
        request.post('group/create', { ...f, type: 'Module', objects: this.value },
          (response) => {
            toast(this, 'success', 'Módulo cadastrado com sucesso')
            console.log(response)
            this.$router.push({ path: '/modulos' })
          },
          (error) => {
            toast(this, 'danger', 'Erro', errorFormater(error))
            return Promise.reject(error)
          })
      }
    },
    async copyModule (system, destino) {
      this.loadingCopy = true
      const data =
      await this.$store.dispatch('moduleToCopy', JSON.stringify({
        base_object: this.$store.state.rotas.items.objects.filter(a => a.key === system).map(a => a.id)[0],
        new_object: this.$store.state.rotas.items.objects.filter(a => a.key === destino).map(a => a.id)[0]
      }))
      this.loadingCopy = false

      console.log(data)
    },
    async updateRoute (route) {
      const data = {
        base_object: this.$store.state.rotas.items.objects.filter(a => a.key === route).map(a => a.id)[0],
        new_object: this.$store.state.rotas.items.objects.filter(a => a.key === this.selectedModuleToCopy).map(a => a.id)[0]
      }
      const response = await api.post('/object/copy', data)
      toast(this, 'success', 'Rota copiada', 'Rota copiada com sucesso')
      console.log(response)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../style/*.styl';

.select
  border-width: 0.1px
  opacity 0.7
  border-color: info
  border-style: solid

.modulo_tag{
    height: 2rem;
    font-size: 1.5rem;
}
 .wrapper
    padding-top: 0 !important

#select-title{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: min-content
    align-content: center;
}

#clonar_dicionarios {
    background-color: #fff;
    justify-content: center;
    padding: 1%;
    margin: auto;
    border-radius: 15px;
}

#clonar_dicionarios2 {
    background-color: #fff;
    justify-content: center;
    padding: 1%;
    margin: auto;
    border-radius: 15px;
}

#rotas {
    justify-content: center;
    padding: 1%;
    margin: auto;
    border-radius: 15px
}
.btns
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  align-content: center;

.title-btn
    margin-left auto
    background-color primary
    color white
    border-radius 8px
    padding 10px 12px
    cursor pointer
    transition 0.2s

      &:hover
      background-color alpha(primary, 0.8)

      .label
        margin-left 5px

      span, .icon-btn
        vertical-align middle
        no-select()

</style>
